import { EventEmitter } from "events";

export enum Key {
    homeJsonDE = 'homeJsonDE',
    homeJsonEN = 'homeJsonEN',
    expire = '86400000',
    addListenerChangeLanguage = 'com.petesso.joinr.website.language',
    downloadJoinrLocation = 'com.joinr.website.downloadLocation',
    langaugeKey = 'com.petesso.joinr'
}

export const EventListener = new EventEmitter()

export const language = localStorage.getItem(Key.langaugeKey)

export function setDownloadJoinrLocation(location: number) {
    let locationString = ("" + location)
    localStorage.setItem(Key.downloadJoinrLocation, locationString)
}

export function getDownloadJoinrLocation() {
    return localStorage.getItem(Key.downloadJoinrLocation)
}

export function setLocalStorage(key: string, value: any, ttl: string) {
    let now = new Date()
    let item = {
        value: value,
        expire: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getLocalStorage(key: string) {
    let itemStr: any = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }

    let item = JSON.parse(itemStr)
    let now = new Date()
    // localStorage.removeItem(key)

    if (now.getTime() > item.expire) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export function fetchDEJson(page: string) {
    let fetchString = 'https://joinr-layouts.s3.eu-central-1.amazonaws.com/web/' + page + '/de.json'
    let result = fetch(fetchString)
        .then(response => response.json())
        .then((jsonData) => {
            return jsonData
        })
    return result
}

export function fetchENJson(page: string) {
    let fetchString = 'https://joinr-layouts.s3.eu-central-1.amazonaws.com/web/' + page + '/en.json'
    let result = fetch(fetchString)
        .then(response => response.json())
        .then((jsonData) => {
            return jsonData
        })
    return result
}
