import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { MAPSYMBOLS_SVG, CREATEORJOIN_SVG, PICTUREGALLERY_SVG, UPDATES_SVG, DASH_SVG, CHAT_SVG, APPSTORE_SVG, GOOGLEPLAY_SVG } from '../../imports';
import '../styles.scss';
import PageContainer from '../PageContainer';

interface Props { }

interface State { }

const APPIMAGE1 = require("../../assets/appImage1.png");
const APPIMAGE2 = require("../../assets/appImage2.png");

export default class App extends React.Component<Props, State> {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <PageContainer>
        <Header />
        <div className="app">
          <div className="mainFrame">
            <div className="pageContent">

              <img className="top-image" src={APPIMAGE1} alt="" />

              <div className="red-square">
                <div className="headline">BE A LEADER</div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="square-content1">
                    <div className="headline">READY, SET, GO ...</div>
                    <div className="seperator" />
                    <div className="infoText">joinr hilft Dir dabei Dein individuelles Gruppenevent zu planen, ob mit Auto, Fahrrad oder zu Fuß. Der virtuelle Co-Pilot bietet mit intuitiven Features ein außergewöhnliches Rally Erlebnis und hat bereits viele Rally Begeisterte auf ihren spannenden Wegen begleitet.
                              Kreiere einfach Dein Event, lade Freunde ein und setze Start-& Zielpunkt fest.<br /> Let’s Go!</div>
                    <div className="app-row">
                      <a href="https://apps.apple.com/us/app/joinr/id926131432">
                        <img className="icon" src={APPSTORE_SVG} alt="" /></a>
                      <a href="https://play.google.com/store/apps/details?id=de.petesso.joinr&showAllReviews=true">
                        <img className="icon" src={GOOGLEPLAY_SVG} alt="" /></a>
                    </div>
                  </div>
                </div>
                <div className="half-square">
                  <img className="image1" src={APPIMAGE2} alt="" />
                </div>
              </div>

              <div className="white-panel">
                <div className="row">
                  <div className="cols">
                    <img className="icon" src={MAPSYMBOLS_SVG} alt="" />
                    <div className="text">usefull map<br />symbols</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={CREATEORJOIN_SVG} alt="" />
                    <div className="text">create or join<br />events</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={PICTUREGALLERY_SVG} alt="" />
                    <div className="text">picture<br />gallery</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={UPDATES_SVG} alt="" />
                    <div className="text">realtime<br />updates</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={DASH_SVG} alt="" />
                    <div className="text">joinr<br />dash radio</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={CHAT_SVG} alt="" />
                    <div className="text">joinr chat</div>
                  </div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="image2" />
                </div>
                <div className="half-square">
                  <div className="square-content2">
                    <div className="headline">Never lose<br />your group!</div>
                    <div className="seperator" />
                    <div className="infoText">Der praktische joinr Tracking-Pilot hilft Dir dabei Dein Team und das Ziel nicht aus den Augen zu verlieren. Dank Echtzeit-Informationen siehst Du den genauen Standort Deiner Freunde und setzt sie mit nur einem Klick über frühzeitige Verkehrswarnungen in Kenntnis. Mittendrin tanken oder eine kurze Pause einlegen? Nutze hilfreiche Map Symbole für akkurate Boxenstopp Möglichkeiten.</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </PageContainer>
    )
  }
}
