import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";

import { CIRCLE_WHITE_SVG } from "../../imports";
import { PHONE1_SVG } from "../../imports";
import { PHONE2_SVG } from "../../imports";
import { PHONE3_SVG } from "../../imports";
import { PHONE4_SVG } from "../../imports";
import { PHONE5_SVG } from "../../imports";
import { PHONE6_SVG } from "../../imports";

import { FUEL_RUN_SVG } from "../../imports";
import { GRX_SVG } from "../../imports";
import { GR_SVG } from "../../imports";
import { LIONSRUN_SVG } from "../../imports";
import { GSR_SVG } from "../../imports";
import { _550PLUS_SVG } from "../../imports";
import { EOCR_SVG } from "../../imports";
import PageContainer from '../PageContainer';

interface Props { }
interface State { }

export default class Rally extends React.Component<Props, State> {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <PageContainer>
        <Header />
        <div className="rally">
          <div className="mainFrame">
            <div className="pageContent">

              <div className="rally-panel-container">
                <div className="content1">
                  <div className="headline">
                    We support your rally
                          <div className="seperator" />
                  </div>

                  <div className="row">
                    <div className="text"><a href="mailto:support@joinr.us">support@joinr.us</a></div>
                  </div>
                </div>
              </div>

              <div className="rally-phone-panel">
                <div className="content">
                  <div className="rows">
                    <div className="cols">
                      <img className="image" src={PHONE4_SVG} alt="" />
                      <div className="headline">WEGPUNKTE DEFINIEREN</div>
                      <div className="text">Überlege Dir spannende Wegpunkte und setze Start- & Zielpunkt fest.</div>
                    </div>
                    <div className="cols">
                      <img className="image" src={PHONE3_SVG} alt="" />
                      <div className="headline">FIXE ROUTE</div>
                      <div className="text" style={{ width: "80%" }}>Joinr bildet Deine individuelle Route in einer Roadmap ab. Teilnehmer können sich so an vorgegebenen Punkten und Routen orientieren und navigieren selbstständig zum nächsten Wegpunkt.</div>
                    </div>
                    <div className="cols">
                      <img className="image" src={PHONE1_SVG} alt="" />
                      <div className="headline">RALLYES BEGLEITEN</div>
                      <div className="text">Der Tracking-Pilot hilft Dir das Team und das Ziel nicht aus den Augen zu verlieren. Dank farblich markierter Pins erhältst Du Echtzeit-Informationen über die Rally.</div>
                    </div>
                  </div>
                  <div className="rows">
                    <div className="cols">
                      <img className="image" src={PHONE6_SVG} alt="" />
                      <div className="headline">BESSERES CONRTROLLING</div>
                      <div className="text">Du musst mittendrin tanken oder eine kurze Pause einlegen? Nutze Map-Symbole für akkurate Boxenstopp Möglichkeiten.</div>
                    </div>
                    <div className="cols">
                      <img className="image" src={PHONE5_SVG} alt="" />
                      <div className="headline">ALERTS</div>
                      <div className="text">Nutze Alerts, um deine Gruppe über spontane Ereignisse, wie Tanken oder Stau mit nur einem Klick in Kenntnis zu setzen.</div>
                    </div>
                    <div className="cols">
                      <img className="image" src={PHONE2_SVG} alt="" />
                      <div className="headline">SPECIAL CONTENT</div>
                      <div className="text">Entdecke spannende Features auf dem Homescreen und sei stets über die neusten Trends und Events informiert.</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rally-panel-container">
                <div className="content2">
                  <div className="headline">
                    WHAT WE’VE DONE
                          <div className="seperator" />
                  </div>
                  <div className="row">
                    <div className="logo-container"><img className="logo" src={LIONSRUN_SVG} alt="" /></div>
                    <div className="logo-container"><img className="logo" style={{ height: "60%" }} src={GRX_SVG} /></div>
                    <div className="logo-container"><img className="logo" src={GR_SVG} /></div>
                    <div className="logo-container"><img className="logo" src={FUEL_RUN_SVG} /></div>
                    <div className="logo-container"><img className="logo" src={GSR_SVG} /></div>
                    <div className="logo-container"><img className="logo" style={{ height: "50%" }} src={_550PLUS_SVG} /></div>
                    <div className="logo-container"><img className="logo" src={EOCR_SVG} /></div>
                  </div>
                </div>
              </div>

              <div className="rally-panel-container">
                <div className="content3">
                  <div className="headline">LION’S RUN</div>
                </div>
              </div>

              <div className="rally-panel-container">
                <div className="content4">
                  <div className="headline">FUEL RUN</div>
                </div>
              </div>

              <div className="rally-panel-container">
                <div className="content5">
                  <div className="headline">GOLDRUSH RALLY</div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </PageContainer>
    )
  }
}
