import * as React from 'react';
import ResponsivePage, { Device } from '../ux_elements/responsive-ui/ResponsivePage';
import DesktopPage from '../ux_elements/responsive-ui/DesktopPage';
import TabletOrMobilePage from '../ux_elements/responsive-ui/TabletOrMobilePage';

import "./Header.scss";

import { JOINR_LOGO } from "../../imports";
import { _550PLUS_SVG } from "../../imports";
import { EOCR_SVG } from "../../imports";
import { FUEL_RUN_SVG } from "../../imports";
import { GRX_SVG } from "../../imports";
import { GR_SVG } from "../../imports";
import { LIONSRUN_SVG } from "../../imports";
import { GSR_SVG } from "../../imports";
import SideDrawer from "./drawer";

import { EventListener, Key, getDownloadJoinrLocation } from '../functions';

interface Props {
    home?: boolean;
    art?: boolean;
}

interface State {
    activePage: string;
    pageName: string;
}

export default class Header extends React.Component<Props, State> {

    //eslint-disable-next-line
    constructor(props: Props) {
        super(props);
        this.state = { activePage: "", pageName: this.pageNameOnLoad() }
        this.switchPage = this.switchPage.bind(this);
        this.handleDownloadJoinr = this.handleDownloadJoinr.bind(this)
    }

    pageNameOnLoad(): string {
        switch (window.location.pathname) {
            case "/":
                return "HOME"
            case "/rally":
                return "RALLY"
            case "/app":
                return "THE APP"
            case "/features":
                return "FEATURES"
            case "/art":
                return "ART"
            case "/contact":
                return "CONTACT US"
            default:
                return "";
        }
    }

    switchPage(nextPage: string) {
        if (window.location.pathname !== nextPage) {
            let change: boolean = false;
            switch (nextPage) {
                case "/":
                    this.setState({
                        pageName: "HOME"
                    })
                    change = true;
                    break;
                case "/rally":
                    this.setState({
                        pageName: "RALLY"
                    })
                    change = true;
                    break;
                case "/app":
                    this.setState({
                        pageName: "THE APP"
                    })
                    change = true;
                    break;
                case "/features":
                    this.setState({
                        pageName: "FEATURES"
                    })
                    change = true;
                    break;
                case "/art":
                    this.setState({
                        pageName: "ART"
                    })
                    change = true;
                    break;
                case "/contact":
                    this.setState({
                        pageName: "CONTACT US"
                    })
                    change = true;
                    break;
                default:
                    change = false;
                    break;
            }
            if (change) {
                this.setState({
                    activePage: window.location.pathname,
                });
                window.location.pathname = nextPage;
            }
            else
                alert("Found dead link");
        }
    }

    getActiveBox(thisPage: string) {
        if (window.location.pathname === thisPage) {
            return (
                <div style={{ backgroundColor: this.props.art ? "#E5007D" : "#DD1733", width: '100%', height: 3, borderRadius: 90, marginTop: -3 }} />
            )
        }
    }

    componentDidMount() {
        localStorage.setItem(Key.langaugeKey, 'de')
    }

    changeLanguage = () => {
        let language = localStorage.getItem(Key.langaugeKey)
        if (language) {
            if (language === 'de') {
                localStorage.setItem(Key.langaugeKey, 'en')
                EventListener.emit(Key.addListenerChangeLanguage, 'en')
            } else {
                localStorage.setItem(Key.langaugeKey, 'de')
                EventListener.emit(Key.addListenerChangeLanguage, 'de')

            }
        } else {
            localStorage.setItem(Key.langaugeKey, 'en')
            EventListener.emit(Key.addListenerChangeLanguage, 'en')
        }
    }

    handleDownloadJoinr() {
        let location = getDownloadJoinrLocation()
        let offset = 200
        if (location) {
            window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: +location - offset
            })
        }
    }

    render() {
        return (
            <ResponsivePage>
                {(device) => {
                    return (
                        <>
                            <DesktopPage>
                                <div className="desktop">
                                    <header className={!this.props.home ? "header" : "headerHome"} style={{ backgroundColor: this.props.art ? "#222223" : "" }}>
                                        <div className="header-content">
                                            <div className="headerRow">
                                                <div className="headerCols">
                                                    <div onClick={() => this.switchPage("/")} className="headerText">
                                                        HOME
                                                        {this.getActiveBox("/")}
                                                    </div>
                                                </div>
                                                <div className="headerCols">
                                                    <div onClick={() => this.switchPage("/rally")} className="headerText">
                                                        RALLY
                                                        {this.getActiveBox("/rally")}
                                                    </div>
                                                </div>
                                                <div className="headerCols">
                                                    <div onClick={() => this.switchPage("/app")} className="headerText">
                                                        THE APP
                                                        {this.getActiveBox("/app")}
                                                    </div>
                                                </div>
                                                <div className="headerCols">
                                                    <div onClick={() => window.location.pathname != "/" ? window.location.pathname = "/" : ""} style={{ cursor: "pointer" }}><img className="logo" src={JOINR_LOGO} /></div>
                                                </div>
                                                <div className="headerCols">
                                                    <div onClick={() => this.switchPage("/features")} className="headerText">
                                                        Features
                                                        {this.getActiveBox("/features")}
                                                    </div>
                                                </div>
                                                {/* <div className="headerCols"> 
                                                    <div onClick={() => this.switchPage("/art")} className="headerText">
                                                        ART
                                                        {this.getActiveBox("/art")}
                                                    </div>
                                                </div> */}
                                                <div className="headerCols">
                                                    <div onClick={() => this.switchPage("/contact")} className="headerText">
                                                        CONTACT
                                                        {this.getActiveBox("/contact")}
                                                    </div>
                                                </div>
                                                <div className="headerCols">
                                                    <div onClick={() => this.changeLanguage()} className="headerText">
                                                        DE/EN
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    this.props.home ?
                                                        <div className="headerHomeText">
                                                            <div className="headline">JOINR SUPPORTS YOUR RALLY</div>
                                                            <div className="seperator" />
                                                            <div className="subheader" style={{ cursor: 'pointer' }} onClick={() => { this.handleDownloadJoinr() }}>DOWNLOAD JOINR NOW</div>
                                                        </div>
                                                        : <></>
                                                }
                                            </div>
                                        </div>

                                    </header>
                                    <div>
                                        {
                                            this.props.home ?
                                                <div className="redBanner">
                                                    <div className="logo-container"><img className="logo" src={FUEL_RUN_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" style={{ height: "30%" }} src={GRX_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" src={GR_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" style={{ height: "40%" }} src={LIONSRUN_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" src={GSR_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" style={{ height: "30%" }} src={_550PLUS_SVG} /></div>
                                                    <div className="logo-container"><img className="logo" src={EOCR_SVG} /></div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </DesktopPage>
                            <TabletOrMobilePage>
                                <div className="mobile">
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className={this.props.home ? "home" : (this.props.art ? "container-art" : "container-normal")}>
                                            <div className="content">
                                                <div className="section" style={{ alignItems: "flex-start" }}>
                                                    <SideDrawer changeLanguage={() => this.changeLanguage()} art={this.props.art} switchPage={this.switchPage} drawerText="7vw" size="7vw" />
                                                </div>
                                                <div className="section" style={{ justifyContent: "center", width: "60%" }}>
                                                    <div className="title">{this.state.pageName}</div>
                                                </div>
                                                <div className="section" onClick={() => window.location.pathname != "/" ? window.location.pathname = "/" : ""} style={{ alignItems: "flex-end", paddingLeft: "1.5rem", cursor: "pointer" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <img className="logo" src={JOINR_LOGO} />
                                                        <div className="headline">JOINR!</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.props.home ?
                                                <div className="redBanner-mobile">
                                                    <div className="logo-container"><img className="logo-banner" src={FUEL_RUN_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" style={{ height: "30%" }} src={GRX_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" src={GR_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" style={{ height: "40%" }} src={LIONSRUN_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" src={GSR_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" style={{ height: "30%" }} src={_550PLUS_SVG} /></div>
                                                    <div className="logo-container"><img className="logo-banner" src={EOCR_SVG} /></div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </TabletOrMobilePage>
                        </>
                    )
                }}

            </ResponsivePage>

        )
    }
}
