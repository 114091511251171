import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";
import PageContainer from '../PageContainer';

interface Props { }

interface State { }

const ARTLOGO = require("../../assets/ArtLogo.svg");
const SHOE = require("../../assets/tmpShoe.png");

export default class Art extends React.Component<Props, State> {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <PageContainer>
      <div className="art">
        <Header art />
        <div className="mainFrame" style={{ backgroundColor: "#222223" }}>
          <div className="pageContent">

            <div className="panel-container">
              <div className="content1">
                <img className="logo" src={ARTLOGO} alt="" />
              </div>
            </div>

            <div className="white-square1">
              <div className="content">
                <div className="headline">
                  JOINR ART
                        <div className="seperator" />
                </div>
                <div className="infoText">Mit joinr Art verleihen wir Dir das gewisse Etwas für Deinen individuellen Gruppentrip. Wir designen Deine Lieblingsschuhe und wandeln sie in wahre Unikate um. Jedes Design wird mit viel Liebe zum Detail von Hand bemalt und entspricht dabei ganz Deinen Vorstellungen. Von Gruppenlogo über das Abbild eines Fahrzeugs oder Ausgefallenes. Der Phantasie sind dabei keine Grenzen gesetzt.
                        Du möchtest einen Schuh, den sonst niemand hat oder auch einen Wiedererkennunswert für Deine Rally Teilnehmer schaffen? Dann melde Dich bei uns!</div>
              </div>
            </div>

            <div className="panel-row">
              <div className="panel">
                <img className="shoe1" src={SHOE} alt="" />
              </div>
              <div className="panel">
                <img className="shoe2" src={SHOE} alt="" />
              </div>
              <div className="panel">
                <img className="shoe3" src={SHOE} alt="" />
              </div>
            </div>

            <div className="panel-container">
              <div className="content2">

              </div>
            </div>

          </div>
        </div>
        <Footer art />
      </div>
      </PageContainer>
    )
  }
}
