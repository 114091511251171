import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from "./components/pages/Home";
import Rally from "./components/pages/Rally";
import App from "./components/pages/App";
import Features from "./components/pages/Features";
import Art from "./components/pages/Art";
import Contact from "./components/pages/Contact";


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/rally" component={Rally} />
            <Route exact path="/app" component={App} />
            <Route exact path="/features" component={Features} />
            <Route exact path="/art" component={Art} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/password_reset/*" component={() => <Home password_reset={true} />} />

            {/* Failback */}
            <Route component={Home} />
        </Switch>
    </BrowserRouter>
    , document.getElementById("root"));
