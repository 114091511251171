import * as React from 'react';
import '../ux_styles.css';
import MediaQuery from 'react-responsive';			//https://github.com/contra/react-responsive
import DesktopPage from './DesktopPage';
import LandscapePage from './LandscapePage';
import PortraitPage from './PortraitPage';
import TabletPage from './TabletPage';
import MobilePage from './MobilePage';

interface Props {
    children: (device: Device, portrait: boolean)=>React.ReactNode
}

export const DEVICE_WIDTH_DESKTOP = 1224;
export const DEVICE_WIDTH_TABLET = 768;

export enum Device {
	DESKTOP,
	TABLET,
	MOBILE
}

export default class ResponsivePage extends React.Component<Props, {}> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<>
			<DesktopPage>
				<LandscapePage>
					{this.props.children(Device.DESKTOP, false)}
				</LandscapePage>
				<PortraitPage>
					{this.props.children(Device.DESKTOP, true)}
				</PortraitPage>
			</DesktopPage>
			<TabletPage>
				<LandscapePage>
					{this.props.children(Device.TABLET, false)}
				</LandscapePage>
				<PortraitPage>
					{this.props.children(Device.TABLET, true)}
				</PortraitPage>
			</TabletPage>
			<MobilePage>
				<LandscapePage>
					{this.props.children(Device.MOBILE, false)}
				</LandscapePage>
				<PortraitPage>
					{this.props.children(Device.MOBILE, true)}
				</PortraitPage>
			</MobilePage>
			</>
		)
	}
}