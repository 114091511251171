import React from 'react';
import { JOINR_LOGO } from '../../imports';
import { CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

interface ResetPasswordProps {
    onClose: () => void;
    token: string;
}

interface ResetPasswordState {
    newPassword: string;
    confirmPassword: string;
    loading: boolean;
    confirmed: boolean;
    successfull: boolean;
    error: boolean;
}

export const joinrRed: string = '#dd1733'

export default class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    // eslint-disable-next-line
    constructor(props: any) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            loading: false,
            confirmed: false,
            successfull: false,
            error: false
        }
    }

    private handleNewPassword(value: string) {
        this.setState({
            newPassword: value
        })
    }

    private handleConfirmPassword(value: string) {
        if (value === this.state.newPassword && !this.state.confirmed && this.state.newPassword.length > 5) {
            this.setState({
                confirmed: true
            })
        } else if ((value !== this.state.newPassword && this.state.confirmed) || this.state.newPassword.length < 6) {
            this.setState({
                confirmed: false
            })
        }
        this.setState({
            confirmPassword: value
        })
    }

    private handleResetPassword() {
        this.setState({
            loading: true,

        })
        fetch('https://api.rest.joinr-app.de/v1.9/resetpassword/' + this.props.token, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                new_password: this.state.newPassword
            })
        })
            .then((response) => console.log(response.json()
                .then(value => {
                    if (value.error) {
                        this.setState({
                            error: true
                        })
                    } else {
                        this.setState({
                            successfull: true
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        error: true
                    })
                })
            ))
            .then((response) => {
                this.setState({
                    loading: false,
                    confirmPassword: "",
                    newPassword: "",
                    confirmed: false,
                })
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    confirmPassword: "",
                    newPassword: "",
                    confirmed: false
                })
            })
    }

    render() {
        return (
            <div className="dialog-popup">
                <div className="logo-container" style={{ ...(this.state.loading && { paddingTop: 'unset' }) }}>
                    {this.state.loading && <div className="circular-container"> <CircularProgress color="primary" style={{ color: joinrRed }} /> </div>}
                    {!this.state.loading && <>
                        <img className="logo" src={JOINR_LOGO} />
                        <div className="password-text">Password reset</div>
                        {!this.state.successfull && !this.state.error &&
                            <>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className="input-container" style={{ marginBottom: 10, alignItems: 'center' }}>
                                        <input className="password-input" placeholder="new password" type="password" onChange={(value) => this.handleNewPassword(value.target.value)} />
                                    </div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className="input-container">
                                        <input className="password-input" placeholder="confirm password" type="password" onChange={(value) => this.handleConfirmPassword(value.target.value)} />
                                    </div>
                                </div>
                            </>
                        }
                        {this.state.successfull &&
                            <div className="successfull-container">
                                <div className="successfull-text">Successfull</div>
                                <div>
                                    <CheckCircleIcon style={{ color: joinrRed, fontSize: 20 }} />
                                </div>
                            </div>
                        }

                        {this.state.error &&
                            <div className="successfull-container">
                                <div className="successfull-text">Error! Please retry later!</div>
                                <div>
                                    <CancelIcon style={{ color: joinrRed, fontSize: 20 }} />
                                </div>
                            </div>
                        }
                        {!this.state.error && !this.state.successfull &&
                            <div className="successfull-container subtitle">
                                <div className="subtitle-text">*min length 6 character</div>
                            </div>
                        }
                        <div className="password-button-container">
                            {!this.state.error && !this.state.successfull &&
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 10, pointerEvents: 'none', ...(this.state.confirmed && { pointerEvents: 'visible' }) }}>
                                    <div className="popup-button-container" onClick={() => this.handleResetPassword()}>
                                        <div className="popup-button" style={{ ...(!this.state.confirmed && { background: '#dd173175' }) }}>
                                            Reset
                                    </div>
                                    </div>
                                </div>
                            }
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div className="popup-button-container" onClick={() => this.props.onClose()} >
                                    <div className="popup-button cancel">
                                        {(this.state.successfull || this.state.error) ? 'Close' : 'Cancel'}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>}
                </div>
            </div>
        )
    }
}
