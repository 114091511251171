import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import "./Header.scss";
import { Key, EventListener } from '../functions';

const INSTAGRAM = require("../../assets/icons/Instagram-black.svg");
const FACEBOOK = require("../../assets/icons/twitter-black.svg");
const TWITTER = require("../../assets/icons/facebook-black.svg");

const FACEBOOK_HREF = "https://www.facebook.com/joinr.app.us";
const INSTAGRAM_HREF = "https://instagram.com/joinr.us?igshid=13piqiao8nndj";
const TWITTER_HREF = "https://twitter.com/joinr_dev";

interface Props {
  size: string,
  drawerText: string,
  switchPage: any,
  art?: boolean;
  changeLanguage(): void;
}
interface State { }

const TemporaryDrawer = (props: any) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side: any, open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = (side: any) => (
    <div className="drawer" role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)} style={{ backgroundColor: props.art ? "#222223" : "" }}>
      <ListItem className="text-container bg1" onClick={() => props.switchPage("/")}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>HOME</div>
      </ListItem>
      <ListItem className="text-container bg2" onClick={() => props.switchPage("/rally")}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>RALLY</div>
      </ListItem>
      <ListItem className="text-container bg3" onClick={() => props.switchPage("/app")}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>THE APP</div>
      </ListItem>
      <ListItem className="text-container bg4" onClick={() => props.switchPage("/features")}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>FEATURES</div>
      </ListItem>
      <ListItem className="text-container bg5" onClick={() => { props.switchPage("/art") }}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>ART</div>
      </ListItem>
      <ListItem className="text-container bg6" onClick={() => props.switchPage("/contact")}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>CONTACT US</div>
      </ListItem>
      <ListItem className="text-container" onClick={props.changeLanguage}>
        <div className="drawerText" style={{ fontSize: props.drawerText }}>DE/EN</div>
      </ListItem>
      <div style={{ position: "relative", bottom: "2rem", background: props.art ? "#222223" : "#141414" }}>
        {/* <div style={{ fontSize: props.drawerText }} className="headline">SOCIAL</div> */}
        {/* <div style={{ fontSize: props.drawerText }} className="headline">DE/EN</div> */}
        <div className="social-container" style={{ marginTop: 10 }}>


          <a href={INSTAGRAM_HREF}><img style={{ marginLeft: "1.5rem" }} className="icon" src={INSTAGRAM} alt="" /></a>
          <a href={FACEBOOK_HREF}><img className="icon" src={FACEBOOK} alt="" /></a>
          <a href={TWITTER_HREF}><img className="icon" src={TWITTER} alt="" /></a>
        </div>
      </div>

    </div>
  );

  return (
    <div>
      <div className="drawerOpener" style={{ width: props.size }} onClick={toggleDrawer('left', true)}>
        <MenuIcon style={{ fontSize: props.size, color: "white" }} />
      </div>

      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default class SideDrawer extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <TemporaryDrawer changeLanguage={this.props.changeLanguage} art={this.props.art} switchPage={this.props.switchPage} drawerText={this.props.drawerText} size={this.props.size} />
      </div>
    )
  }
}