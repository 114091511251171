import * as React from 'react';
import { Col , Row} from 'reactstrap';
import ResponsivePage, { Device } from '../ux_elements/responsive-ui/ResponsivePage';
import DesktopPage from '../ux_elements/responsive-ui/DesktopPage';
import TabletOrMobilePage from '../ux_elements/responsive-ui/TabletOrMobilePage';

import './Footer.scss';

import {FACEBOOK_BLACK_SVG} from "../../imports";
import {INSTAGRAM_BLACK_SVG} from "../../imports";
import {TWITTER_BLACK_SVG} from "../../imports";

const FACEBOOK_HREF = "https://www.facebook.com/joinr.app.us";
const INSTAGRAM_HREF = "https://instagram.com/joinr.us?igshid=13piqiao8nndj";
const TWITTER_HREF = "https://twitter.com/joinr_dev";

const minWidth: number = 2500;

interface Props {
    art?: boolean;
}

interface State {
    scale: boolean;
}

export default class Footer extends React.Component<Props, State> {
    // eslint-disable-next-line
    constructor(props: Props) {
        super(props);
        this.state = {scale: false}
        if(window.innerHeight > minWidth)
            this.state = {scale: true}
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateSize);
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.updateSize);
    }

    updateSize = () => {
        if(window.innerWidth > minWidth){
            this.setState({
                scale: true,
            })
        }
        else {
            this.setState({
                scale: false,
            })
        }
    }

    render() {
        return (
            <ResponsivePage>
                {(device) => {
                    return(
                        <div>
                            <div className="desktop-footer">
                                <div className="line1">
                                    <h3>© joinr 2020</h3>
                                    <div className="socials-container">
                                        <a href={FACEBOOK_HREF}><img className="icon" src={FACEBOOK_BLACK_SVG} alt=""></img></a>
                                        <a href={INSTAGRAM_HREF}><img className="icon" src={INSTAGRAM_BLACK_SVG} alt=""/></a>
                                        <a href={TWITTER_HREF}><img className="icon" src={TWITTER_BLACK_SVG} alt=""/></a>
                                    </div>
                                </div>
                                <div className="seperator"/>
                                <div className="menu-row">
                                    <h4 onClick={() => window.location.pathname = "/home"} className="elem">HOME</h4>
                                    <h4 onClick={() => window.location.pathname = "/rally"} className="elem">RALLY</h4>
                                    <h4 onClick={() => window.location.pathname = "/app"} className="elem">THE APP</h4>
                                    <h4 onClick={() => window.location.pathname = "/features"} className="elem">FEATURES</h4>
                                    <h4 onClick={() => window.location.pathname = "/contact"} className="elem">CONTACT</h4>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </ResponsivePage>
            
        )
    }
}