import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ResponsivePage from '../ux_elements/responsive-ui/ResponsivePage';
import { POKER_PHONE1_SVG, POKER_PHONE2_SVG, POKER_PHONE3_SVG, MAPSYMBOLS_SVG, CREATEORJOIN_SVG, PICTUREGALLERY_SVG, UPDATES_SVG, DASH_SVG, CHAT_SVG } from '../../imports';
import PageContainer from '../PageContainer';

interface Props { }

interface State {
  scale: boolean,
  smallText: boolean,
}

const POKERIMAGE1 = require("../../assets/pokerImage1.png");

export default class Features extends React.Component<Props, State> {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <PageContainer>
        <Header />
        <div className="features">
          <div className="mainFrame">
            <div className="pageContent">

              <img className="top-image" src={POKERIMAGE1} alt="" />

              <div className="red-square">
                <div className="headline">POKER RUN</div>
              </div>

              <div className="white-square1">
                <div className="content">
                  <div className="headline">
                    POKER RUN
                            <div className="seperator" />
                  </div>
                  <div className="infoText">Mit dem Poker Run Feature verleihst Du deinem Rally Event das Gambling Gefühl auf Rädern.
                    Traditionelle Poker Run Fahrten haben gezeigt, dass das faire Gambling durch manuell ausgehändigte Pokerkarten nicht gewährleistet werden kann.
                    Mit dem Poker Run Feature von joinr kann dies nicht mehr passieren. Durch das Einscannen von QR-Codes erhalten Poker Run Teilnehmer ihre Karten für eine vollständige Pokerhand. Die Karten werden durch den integrierten Casino Algorithmus professionell gemischt und ausgewertet.
                            Eventplaner und Teilnehmer erhalten dank des Leaderboards einen transparenten Überblick über die höchste gespielte Hand aller Teilnehmer</div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="image1" />
                </div>
                <div className="half-square">
                  <div className="image2" />
                </div>
              </div>

              <div className="white-square2">
                <div className="rows" style={{ height: "60%" }}>
                  <div className="cols">
                    <img className="phones" src={POKER_PHONE1_SVG} alt="" />
                  </div>
                  <div className="cols">
                    <img className="phones" src={POKER_PHONE2_SVG} alt="" />
                  </div>
                  <div className="cols">
                    <img className="phones" src={POKER_PHONE3_SVG} alt="" />
                  </div>
                </div>
                <div className="rows" style={{ height: "40%" }}>
                  <div className="cols">
                    <div className="headline">QR CODE</div>
                    <div className="text">Scanne an jedem Touchpoint Deinen QR-Code, der Dir per Zufallsziehung Deine Pokerhand ergänzt.</div>
                  </div>
                  <div className="cols">
                    <div className="headline">POKER CARDS</div>
                    <div className="text">Sammle fünf Karten, um Deine Pokerhand zu vervollständigen.</div>
                  </div>
                  <div className="cols">
                    <div className="headline">LEADERBOARDS</div>
                    <div className="text">Erfahre wie Deine Mitspieler abgeschnitten haben und checke mit dem Leaderboard, ob Du der glückliche Gewinner bist.</div>
                  </div>
                </div>
              </div>

              <div className="points">
                <div className="content">
                  <div className="headline">POINTS</div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="content1">
                    <div className="headline">POINTS SYSTEM</div>
                    <div className="seperator" />
                    <div className="infoText">Belohne Deine Teilnehmer mit attraktiven Rewards für das Einhalten des Eventprogrammes.
                      Der Einsatz des Point Systems hat sich auch bei namhaften Partnern bewährt. Eventplaner verlosten einen ihrer begehrten Startplätze auf der goldRush Rally und setzten Anforderungen, die innerhalb der Rally erfüllt werden mussten.
                              Für folgende Anforderungen konnten Teilnehmer eine bestimmte Punktzahl erzielen:</div>
                  </div>

                </div>
                <div className="half-square">
                  <div className="image3" />
                </div>
              </div>

              <div className="white-panel">
                <div className="row">
                  <div className="cols">
                    <img className="icon" src={MAPSYMBOLS_SVG} alt="" />
                    <div className="text">usefull map<br />symbols</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={CREATEORJOIN_SVG} alt="" />
                    <div className="text">create or join<br />events</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={PICTUREGALLERY_SVG} alt="" />
                    <div className="text">picture<br />gallery</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={UPDATES_SVG} alt="" />
                    <div className="text">realtime<br />updates</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={DASH_SVG} alt="" />
                    <div className="text">joinr<br />dash radio</div>
                  </div>
                  <div className="cols">
                    <img className="icon" src={CHAT_SVG} alt="" />
                    <div className="text">joinr chat</div>
                  </div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="image4" />
                </div>
                <div className="half-square">
                  <div className="content2">
                    <div className="headline">POINTS SYSTEM</div>
                    <div className="seperator" />
                    <div className="infoText">Gestalte auch Du Dein Event mit dem Point System. Das joinr Team berät Dich gerne über weitere Anforderungen, die innerhalb eines Events gesetzt werden können.
                              <br /><br />
                      <a href="mailto:support@joinr.us">support@joinr.us</a></div>
                  </div>
                </div>
              </div>

              <div className="rating">
                <div className="content">
                  <div className="headline">RATING</div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="content3">
                    <div className="headline">RATING</div>
                    <div className="seperator" />
                    <div className="infoText">Das Rating Feature verleiht Deinem Event einen Contest Charakter.
                      Mit dem Bewertungstool von joinr können teilnehmende Fahrzeuge für die schönste Autofolierung bewertet werden.
                      Teilnehmer scannen mittels QR-Code die Fahrzeuge, die ihnen am besten gefallen.
                              Der integrierte Algorithmus bewertet die Stimmen und führt alle Teilnehmer mit der höchsten Punktzahl im Leaderboard auf. Unter den Gewinnern können Preise für das schönste Auto verliehen werden.</div>
                  </div>
                </div>
                <div className="half-square">
                  <div className="image5" />
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="image6" />
                </div>
                <div className="half-square">
                  <div className="content4">
                    <div className="headline">RATING</div>
                    <div className="seperator" />
                    <div className="infoText">Das Bewertungstool von joinr ist nicht nur auf folierte Fahrzeuge begrenzt, es steht für Bewertungen aller Art zur Verfügung.
                              <br /><br />
                      Nutze dieses joinr Feature und hebe dich von anderen Events ab!</div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </PageContainer>
    )
  }
}
