import React from 'react'
import { Fade } from '@material-ui/core';

class FadeInHOC extends React.Component<{}> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (<Fade in={true} timeout={800}>
            <div>
                {this.props.children}
            </div>
        </Fade>);
    }
}

export default FadeInHOC