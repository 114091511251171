import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";

import "../styles.scss";

/* Elements */
import { FASTLANEDRIVE_SVG, HOME_BANNER, ICON_APPSTORE, ICON_PLAYSTORE } from "../../imports";
import { BLR_SVG } from "../../imports";
import { RATX_SVG } from "../../imports";

import ResetPassword from './ResetPassword';

import { getLocalStorage, Key, setLocalStorage, EventListener, fetchDEJson, fetchENJson, setDownloadJoinrLocation } from '../functions';
import PageContainer from '../PageContainer';


interface Props {
  password_reset?: boolean;
}
interface State {
  mobile: boolean;
  password_reset: boolean;
  token?: string;
  jsonDE: any;
  jsonEN: any;
  showingJson: any;
  ref: any;
}


export default class Home extends React.Component<Props, State> {


  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
    this.state = {
      mobile: window.innerWidth <= 1223,
      password_reset: window.location.pathname === '/password_reset',
      jsonDE: "",
      jsonEN: "",
      showingJson: "",
      ref: document.getElementsByClassName('home-middle-panel')
    }
    this.handleOnClose = this.handleOnClose.bind(this)
  }


  handleResize = () => {
    if (window.innerWidth <= 1223) {
      this.setState({
        mobile: true,
      })
    }
    else {
      this.setState({
        mobile: false,
      })
    }
  }

  handleChangeLanguage = (lng: string) => {
    switch (lng) {
      case 'de': {
        this.setState({
          showingJson: this.state.jsonDE
        })
        break;
      }
      case 'en': {
        this.setState({
          showingJson: this.state.jsonEN
        })
        break;
      }
    }
  }

  componentDidMount() {
    let joinrLocation: any = document.getElementsByClassName('home-middle-panel')
    setDownloadJoinrLocation(joinrLocation && joinrLocation[0] && joinrLocation[0].offsetTop)
    // localStorage.clear()
    let actualDE = JSON.parse(getLocalStorage(Key.homeJsonDE));
    let actualEN = JSON.parse(getLocalStorage(Key.homeJsonEN));
    let language = localStorage.getItem(Key.langaugeKey)

    // let actualDE = null

    if (!actualDE) {
      fetchDEJson('home').then(data => {
        setLocalStorage(Key.homeJsonDE, JSON.stringify(data), Key.expire)
        if (language === 'de' || !language) {
          this.setState({
            showingJson: data
          })
        }
        this.setState({
          jsonDE: data
        })
      })
    } else {
      if (language === 'de' || !language) {
        this.setState({
          showingJson: actualDE
        })
      }
      this.setState({
        jsonDE: actualDE
      })
    }

    if (!actualEN) {
      fetchENJson('home').then(value => {
        setLocalStorage(Key.homeJsonEN, JSON.stringify(value), Key.expire)
        if (language === 'en' || !language) {
          this.setState({
            showingJson: value
          })
        }
        this.setState({
          jsonEN: value
        })

      })
    } else {
      if (language === 'en' || !language) {
        this.setState({
          showingJson: actualEN
        })
      }
      this.setState({
        jsonEN: actualEN
      })
    }

    window.addEventListener("resize", this.handleResize);
    EventListener.on(Key.addListenerChangeLanguage, this.handleChangeLanguage)

    if (this.state.password_reset) {
      let href = window.location.href.split('?token=')
      this.setState({
        token: href[1]
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    EventListener.off(Key.addListenerChangeLanguage, this.handleChangeLanguage)
  }

  handleOnClose() {
    window.location.pathname = "/"
    this.setState({
      password_reset: false
    })
  }

  render() {
    return (
      <PageContainer>
        <Header home />
        <div className="home">
          <div className="mainFrame" style={{ paddingTop: this.state.mobile ? "20rem" : "" }}>
            <div className="pageContent">
              {this.state.password_reset &&
                <ResetPassword onClose={this.handleOnClose} token={this.state.token ? this.state.token : ''} />
              }
              <div className="two-square-row">
                <div className="half-square">
                  <div className="image1" style={{ background: `url(${this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel1.img_url : ''})` }} />
                </div>
                <div className="half-square">
                  <div className="square-content">
                    {/* <div className="headline">{de.home.kachel1.title}</div> */}
                    <div className="headline">{this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel1.title : ''}</div>
                    <div className="seperator" />
                    {/* <div className="infoText">Die joinr Plattform ermöglicht Dir das Planen und Steuern Deines individuellen Gruppenevents, ob mit Auto, Fahrrad oder zu Fuß, ohne großen Aufwand.</div> */}
                    <div className="infoText" dangerouslySetInnerHTML={{ __html: this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel1.description : '' }} />
                  </div>
                </div>
              </div>

              <div className="home-middle-panel" style={{ backgroundImage: `url(${HOME_BANNER})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className="content" style={{ justifyContent: 'center' }}>
                  <div className="headline" style={{ textAlign: 'center', color: 'white', fontSize: '2rem' }}>Starte jetzt und lade Dir die joinr App kostenlos herunter!</div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="seperator" style={{ width: '80%' }} />
                    <div className="home-button-container" style={{ width: '100%' }}>
                      <div className="button-container">
                        <div className="popup-button-container">
                          <div className="popup-button" onClick={() => { window.open('https://apps.apple.com/de/app/joinr/id926131432', "_blank") }}>
                            <div>
                              <img src={ICON_APPSTORE} width={12} alt="APPSTORE" />
                            </div>
                            <div className="button-text">
                              App Store
                          </div>
                          </div>
                          <div className="popup-button playstore" onClick={() => { window.open('https://play.google.com/store/apps/details?id=de.petesso.joinr', "_blank") }}>
                            <div>
                              <img src={ICON_PLAYSTORE} width={12} alt="APPSTORE" />
                            </div>
                            <div className="button-text">
                              Play Store
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="square-content">
                    <div className="headline">{this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel2.title : ''}</div>
                    <div className="seperator" style={{ width: '90%' }} />
                    <div className="infoText" dangerouslySetInnerHTML={{ __html: this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel2.description : '' }} />
                  </div>
                </div>
                <div className="half-square">
                  <div className="image2" style={{ background: `url(${this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel2.img_url : ''})` }} />
                </div>
              </div>



              <div className="home-middle-panel">
                <div className="content">
                  <div className="headline">JOIN RALLYS</div>
                  <div className="seperator" />
                  <div className="lower-half">
                    <div className="row">
                      <div className="icon-container">
                        <img className="icon" style={{ height: "90%" }} src={FASTLANEDRIVE_SVG} alt="" />
                      </div>
                      <div className="icon-container">
                        <img className="icon" style={{ height: "80%" }} src={BLR_SVG} alt="" />
                      </div>
                      <div className="icon-container">
                        <img className="icon" src={RATX_SVG} alt="" />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "1rem" }}>
                      <div className="text-container">
                        <div className="headline">FAST LANE DRIVE RALLY</div>
                      </div>
                      <div className="text-container">
                        <div className="headline">BASE LINE RALLY</div>
                      </div>
                      <div className="text-container">
                        <div className="headline">Race Armada texas</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="image1" style={{ background: `url(${this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel3.img_url : ''})` }} />
                </div>
                <div className="half-square">
                  <div className="square-content">
                    <div className="headline">{this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel3.title : ''}</div>
                    <div className="seperator" />
                    <div className="infoText" dangerouslySetInnerHTML={{ __html: this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel3.description : '' }} />
                  </div>
                </div>
              </div>

              <div className="two-square-row">
                <div className="half-square">
                  <div className="square-content">
                    <div className="headline">{this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel4.title : ''}</div>
                    <div className="seperator" style={{ width: '90%' }} />
                    <div className="infoText" dangerouslySetInnerHTML={{ __html: this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel4.description : '' }} />
                  </div>
                </div>
                <div className="half-square">
                  <div className="image2" style={{ background: `url(${this.state.showingJson && this.state.showingJson.home ? this.state.showingJson.home.kachel4.img_url : ''})` }} />
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </PageContainer>
    )
  }
}
