import { Zoom, Fab, makeStyles, useMediaQuery } from '@material-ui/core';
import React from 'react';
import ArrowUpIcon from '@material-ui/icons/ExpandLessOutlined';
import FadeInHOC from './FadeInHoc';
import { joinrRed } from './pages/ResetPassword';

export default class PageContainer extends React.Component<{}, { scrollUpButtonVisible: boolean }> {

    constructor(props: any) {
        super(props)
        this.state = {
            scrollUpButtonVisible: false
        }

        this.listener = this.listener.bind(this)
    }

    listener = (event: any) => {
        let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
        let scrolledFromTop = (event && event.target && event.target.scrollingElement && event.target.scrollingElement.scrollTop) || 0
        let shouldBeVisible = scrolledFromTop > viewportHeight / 2

        this.setState({
            scrollUpButtonVisible: shouldBeVisible
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.listener)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listener)
    }

    render() {
        return (
            <FadeInHOC>
                <div>
                    {this.props.children}
                </div>
                <FabButton visible={this.state.scrollUpButtonVisible} onClick={() => window.scrollTo({ behavior: "smooth", top: 0, left: 0 })} />
            </FadeInHOC>
        )
    }
}

const FabButton: React.SFC<{ visible: boolean, onClick: () => void }> = (props) => {
    const classes = makeStyles(theme => ({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: joinrRed,
            color: 'white',

            "&:hover": {
                backgroundColor: joinrRed,
                color: 'white',
            },

            "&:focus": {
                outline: 'none !important'
            }
        },
    }))()

    return (
        <Zoom
            timeout={300}
            in={props.visible}
            unmountOnExit>
            <Fab aria-label={""} className={classes.fab} color="primary" onClick={props.onClick}>
                <ArrowUpIcon />
            </Fab>
        </Zoom>
    )
}