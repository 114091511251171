import React from 'react';
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { TextField, Button } from '@material-ui/core/';
import { CircularProgress } from '@material-ui/core';
import PageContainer from '../PageContainer';

interface Props { }

interface State {
  mobile: boolean;
  name: string;
  email: string;
  message: string;
  send: boolean;
  error: boolean;
  loading: boolean;
}

export default class Contact extends React.Component<Props, State> {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props);
    this.state = {
      mobile: window.innerWidth <= 1223,
      name: "",
      email: "",
      message: "",
      send: false,
      error: false,
      loading: false
    }

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize = () => {
    if (window.innerWidth <= 1223) {
      this.setState({
        mobile: true,
      })
    }
    else {
      this.setState({
        mobile: false,
      })
    }
  }

  private handleName(value: string) {
    this.setState({
      name: value
    })
  }

  private handleEmail(value: string) {
    this.setState({
      email: value
    })
  }

  private handleMessage(value: string) {
    this.setState({
      message: value
    })
  }

  private handleContact() {
    this.setState({
      loading: true
    })
    fetch('https://api.rest.joinr-app.de/v1.9/web-contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
      })
    })
      .then((response) => console.log(response.json()
        .then(value => {
          if (value.error) {
            this.setState({
              error: true,
            })
          } else {
            this.setState({
              send: true
            })
          }
        })
        .catch(err => {
          this.setState({
            error: true
          })
        })
      ))
      .then((response) => {
        this.setState({
          loading: false
        })
      })
      .catch((err) => {
        this.setState({
          loading: false
        })
      })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <PageContainer>
        <Header />
        <div className="contact">
          <div className="mainFrame">
            <div className="pageContent">

              <div className="panel-container">
                <div className="content">
                  <div className="headline">
                    CONTACT US
                            <div className="seperator" />
                    <div className="text"><a href="mailto:support@joinr.us">support@joinr.us</a></div>
                  </div>
                </div>
              </div>

              <div className="text-panel">
                <div className="content">
                  <div className="headline">HEY DU!</div>
                  <div className="text">Du hast schon einen Trip in Aussicht, weißt aber nicht, wie Du joinr zu
                      Deinem persönlichen Begleiter gestaltest? Dann melde Dich bei uns!
                      Wir helfen Dir gerne bei der Planung deiner nächsten Reise mit joinr.
                              <br /><br />
                    Wir freuen uns auf Dich!</div>
                </div>
              </div>

              <div className="contact-panel">
                <div className="content">
                  {this.state.send && <div className="email-send-text">Email wurde versendet!</div>}
                  {this.state.error && <div className="email-send-text">Fehler! Versuche es bitte später nocheinmal!</div>}
                  <div className="row">
                    <TextField className="row-textfield" label="Full Name" variant="outlined" onChange={(value) => { this.handleName(value.target.value) }} />
                    <TextField className="row-textfield" label="E-Mail" variant="outlined" onChange={(value) => { this.handleEmail(value.target.value) }} />
                  </div>
                  <TextField className="main-textfield" multiline={true} rows={this.state.mobile ? 7 : 10} fullWidth label="Nachricht" variant="outlined" onChange={(value) => { this.handleMessage(value.target.value) }} />
                  <Button variant="contained" disabled={this.state.name.length < 1 || this.state.email.length < 1 || this.state.message.length < 1} disableElevation={true} className="submit-button" onClick={() => { this.handleContact() }}>{this.state.loading ? <CircularProgress color="primary" style={{ color: 'white' }} /> : 'Senden'}</Button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </PageContainer>
    )
  }
}
