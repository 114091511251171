import * as React from 'react';
import '../ux_styles.css';
import MediaQuery from 'react-responsive';

interface Props {
    
}

export default class extends React.Component<Props, {}> {

	constructor(props: Props) {
		super(props);
	}

	render() {
		return (
			<MediaQuery orientation="portrait">
				{this.props.children}
			</MediaQuery>
		)
	}
}
