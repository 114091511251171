/* Social Media */
export const FACEBOOK_BLACK_SVG = require("./assets/icons/facebook-black.svg");
export const INSTAGRAM_BLACK_SVG = require("./assets/icons/Instagram-black.svg");
export const TWITTER_BLACK_SVG = require("./assets/icons/twitter-black.svg");

/* Other */
export const JOINR_LOGO = require("./assets/logo.svg");
export const HOMEIMAGE1 = require("./assets/homeImage1.png");
export const FASTLANEDRIVE_SVG = require("./assets/icons/fastLaneDrive.svg");
export const BLR_SVG = require("./assets/icons/blr.svg");
export const RATX_SVG = require("./assets/icons/ratx.svg");
export const CIRCLE_WHITE_SVG = require("./assets/icons/circle_white.svg");
export const MAPSYMBOLS_SVG = require("./assets/icons/mapSymbols.svg");
export const CREATEORJOIN_SVG = require("./assets/icons/createOrJoin.svg");
export const PICTUREGALLERY_SVG = require("./assets/icons/pictureGallery.svg");
export const UPDATES_SVG = require("./assets/icons/updates.svg");
export const DASH_SVG = require("./assets/icons/dash.svg");
export const CHAT_SVG = require("./assets/icons/chat.svg");
export const SHADOW_SVG = require("./assets/icons/appShadow.svg");
export const APPSTORE_SVG = require("./assets/icons/appstore.svg");
export const GOOGLEPLAY_SVG = require("./assets/icons/googleplay.svg");
export const HOME_BANNER = require("./assets/Home_Banner.png");
export const ICON_PLAYSTORE = require('./assets/icons/icon_playstore.svg');
export const ICON_APPSTORE = require('./assets/icons/icon_apple.svg');

/* Header Icons */
export const _550PLUS_SVG = require("./assets/icons/550plus.svg");
export const EOCR_SVG = require("./assets/icons/eocr.svg");
export const FUEL_RUN_SVG = require("./assets/icons/fuel_run.svg");
export const GR_SVG = require("./assets/icons/gr.svg");
export const GRX_SVG = require("./assets/icons/grx.svg");
export const LIONSRUN_SVG = require("./assets/icons/lionsrun.svg");
export const GSR_SVG = require("./assets/icons/gsr.svg");

/* RallyPage phone images */
export const PHONE1_SVG = require("./assets/rallyPhone/phone1.svg")
export const PHONE2_SVG = require("./assets/rallyPhone/phone2.svg")
export const PHONE3_SVG = require("./assets/rallyPhone/phone3.svg")
export const PHONE4_SVG = require("./assets/rallyPhone/phone4.svg")
export const PHONE5_SVG = require("./assets/rallyPhone/phone5.svg")
export const PHONE6_SVG = require("./assets/rallyPhone/phone6.svg")

/* PokerPage phone images */
export const POKER_PHONE1_SVG = require("./assets/pokerPhone/pokerPhone1.svg");
export const POKER_PHONE2_SVG = require("./assets/pokerPhone/pokerPhone2.svg");
export const POKER_PHONE3_SVG = require("./assets/pokerPhone/pokerPhone3.svg");